import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { Figure } from "components/docs/Figure";
// import { Color, magma, dmax, dmin } from "../../../styles/magma/_variables";
import * as styles from "./styles.module.scss";

const Schematic = ({
  children,
  // imagePath,
  title,
  iconName = "MathCompass",
  showIcon = true,
  className,
  annotations,
  ...other
}) => {
  // const ImageTag = null;

  // FIXME: export desired colors from local sass file instead
  // and use those to populate runtime css variables?

  // const baseColor = Color.mix(magma.neutral, magma.primary, 15);
  // const titleBgColor = baseColor;
  // // const stageBgColor = Color.mix(baseColor, dmax, 55).toHexString();
  // const iconColor = Color.mix(baseColor, dmin, 45).saturate(25);

  return (
    <Figure
      {...other}
      // style={{
      //   "--icon-color": iconColor,
      //   // "--stage-bgcolor": stageBgColor,
      //   "--title-bgcolor": titleBgColor,
      // }}
      subject={children}
      title={title}
      figureTypeName="Schematic"
      iconName={iconName}
      showIcon={showIcon}
      annotations={annotations}
      className={classNames(styles.Schematic, "Schematic", className)}
    />
  );
};

export { Schematic };
