// extracted by mini-css-extract-plugin
export var FeatureMenuWrapper = "styles-module--FeatureMenuWrapper--2FuYB";
export var FeatureMenuNav = "styles-module--FeatureMenuNav--37n0d";
export var FeatureMenuLink = "styles-module--FeatureMenuLink--3igSt";
export var disabled = "styles-module--disabled--2E4m1";
export var FeatureIcon = "styles-module--FeatureIcon---BDdE";
export var focus = "styles-module--focus--2XIDc";
export var active = "styles-module--active--2T2M6";
export var FeatureTitle = "styles-module--FeatureTitle--2Zs0v";
export var MenuTitle = "styles-module--MenuTitle--rCLoh";
export var isOpen = "styles-module--isOpen--hIKh5";
export var FeatureMenuToggle = "styles-module--FeatureMenuToggle--1ekkR";
export var ToggleButton = "styles-module--ToggleButton--RvS5s";
export var HamburgerBox = "styles-module--HamburgerBox--gOqad";
export var Hamburger = "styles-module--Hamburger--uDLg4";
export var isActive = "styles-module--isActive--ZZr6E";
export var HamburgerInner = "styles-module--HamburgerInner--3vZ5p";
export var HamburgerRightArrow = "styles-module--HamburgerRightArrow--2ZuLa";
export var HamburgerLeftArrow = "styles-module--HamburgerLeftArrow--3AQrc";