/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 *
 */
const React = require("react");
const ReactDOM = require("react-dom");

const Layout =
  require("./src/components/layout/SiteLayout/SiteLayout").SiteLayout;

// require("prismjs/themes/prism-solarizedlight.css")

// Support persistent 'Layout' so whole page doesn't re-mount on click
exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

// this is a hack to fix missing styles on refresh in production
// reference: https://github.com/gatsbyjs/gatsby/issues/17676#issuecomment-535796737
exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
};
