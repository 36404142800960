import React from "react";
import classNames from "classnames";

import { Schematic } from "components/docs/Schematic";

import SvgFormBasicInitial from "images/svgr/screens/form_basic_initial.svg";
import SvgFormFieldValidation from "images/svgr/screens/form_field_validation.svg";
import SvgFormValidationError from "images/svgr/screens/form_validation_error.svg";
import SvgFormWidgetExamples from "images/svgr/screens/form_widget_examples.svg";
import SvgFormUpdateDelete from "images/svgr/screens/form_basic_update_delete.svg";
import SvgFormResponsive from "images/svgr/screens/form_responsive.svg";

const Figure1 = () => {
  const annotations = [
    {
      id: "form-create-instructions",
      target: "XG2-instructions1",
      text: "Forms need to be prefaced with instructions for accessibility and usability",
      mask: true,
    },
    {
      id: "form-create-legend",
      target: "XG2-fw-bolder1",
      text: "Fieldset legend provides additional structural context to related inputs",
      mask: true,
    },
    {
      id: "form-create-input",
      target: "XG2-title",
      text: "Placeholder text can provide completion cues for initial form completion",
      mask: true,
    },
    {
      id: "form-create-inputHelp",
      target: "XG2-titleHelpText",
      text:
        "Field help provides additional context that remain visible " +
        "when the field value is populated",
      mask: true,
    },
    {
      id: "form-create-optionalFields",
      target: "XG2-fw-bold2",
      text:
        "Fields are required by default; optional fields are identified by " +
        "styled text label",
      mask: true,
    },
    {
      id: "form-create-abort",
      target: "XG2-me-auto1",
      text:
        "Cancel/Close option presented at the same scope as " +
        "completion operation(s)",
      mask: true,
    },
    {
      id: "form-create-button",
      target: "XG2-btn3",
      text: "Create operation button uses 'success' theme color",
      mask: true,
    },
  ];

  return (
    <Schematic title="Create Form Initial View" annotations={annotations}>
      <SvgFormBasicInitial />
    </Schematic>
  );
};

const Figure2 = () => {
  const annotations = [
    {
      id: "field-error-1",
      target: "BF4-title",
      text: "Fields validated as containing errors are highlighted in red",
      mask: true,
    },
    {
      id: "field-error-2",
      target: "BF4-field-error-icon",
      text: "Fields containing errors are marked with an icon",
      mask: true,
    },
    {
      id: "field-error-3",
      target: "BF4-invalid-feedback1",
      text:
        "Fields containing errors are explained through text feedback " +
        "which replaces help text (if present)",
      mask: true,
    },
    {
      id: "field-error-4",
      target: "BF4-btn3",
      text:
        "Button(s) remain active even when invalid data is present for " +
        "accessibiltiy/usability reasons",
      mask: true,
    },
  ];

  return (
    <Schematic title="Field Validation Error" annotations={annotations}>
      <SvgFormFieldValidation />
    </Schematic>
  );
};

const Figure3 = () => {
  const annotations = [
    {
      id: "form-error-1",
      target: "N73-fade1",
      text:
        "When a form fails to submit successfully, an explanatory alert " +
        "appears before the form, replacing any instructions",
      mask: true,
    },
    {
      id: "form-error-2",
      target: "N73-col1",
      text: "Fields failing validation are clearly identified for remediation",
      mask: true,
    },
  ];

  return (
    <Schematic title="Form Validation Error" annotations={annotations}>
      <SvgFormValidationError />
    </Schematic>
  );
};

const Figure4 = () => {
  const annotations = [
    {
      id: "form-widgets-1",
      target: "JY1-mb-31",
      text:
        "Dropdown selects are useful for offering lists of options without " +
        "using screen real estate when inactive",
      mask: true,
    },
    {
      id: "form-widgets-2",
      target: "JY1-mb-32",
      text:
        "Radio button options are preferable for a short list of options " +
        "requiring additional details for a user to make a selection",
      mask: true,
    },
    {
      id: "form-widgets-3",
      target: "JY1-mb-33",
      text: "Toggle switches are useful for indicating/setting feature enablemenet",
      mask: true,
    },
  ];

  return (
    <Schematic title="Form Widget Examples" annotations={annotations}>
      <SvgFormWidgetExamples />
    </Schematic>
  );
};

const Figure5 = () => {
  const annotations = [
    {
      id: "form-modify-1",
      target: "Q6D-title",
      text: "Existing values are populated in appropriate field inputs",
      mask: true,
    },
    {
      id: "form-modify-2",
      target: "Q6D-row2",
      text: "Operations are presented in row at end of form",
      mask: true,
    },
    {
      id: "form-modify-3",
      target: "Q6D-ms-auto1",
      text:
        "Dangerous/deleterious operations are identified with 'danger' styling " +
        "and protected by confirmation dialog",
      mask: true,
    },
    {
      id: "form-modify-4",
      target: "Q6D-btn3",
      text: "Update operation identified using 'primary' styling",
      mask: true,
    },
  ];

  return (
    <Schematic title="Modify Form Initial View" annotations={annotations}>
      <SvgFormUpdateDelete />
    </Schematic>
  );
};

const Figure6 = () => {
  const annotations = [
    {
      id: "form-responsive-1",
      target: "HMT-fw-bolder1",
      text: "Fieldset legend moves from side to top of form",
      mask: true,
    },
    {
      id: "form-responsive-2",
      target: "HMT-title",
      text: "Text input fields fill available screen width",
      mask: true,
    },
  ];

  return (
    <Schematic
      title="Responsive Form View"
      annotationTitle={"Features"}
      annotations={annotations}
    >
      <SvgFormResponsive />
    </Schematic>
  );
};

export { Figure1, Figure2, Figure3, Figure4, Figure5, Figure6 };
