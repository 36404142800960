import { useState, useEffect, useRef } from "react";
import dotProp from "dot-prop";

const isBrowser = typeof window !== "undefined";

function useStickyState(defaultValue, key) {
  const [value, setValue] = useState(() => {
    const stickyValue = isBrowser ? window.localStorage.getItem(key) : null;
    let result = defaultValue;

    if (stickyValue !== null) {
      try {
        result = JSON.parse(stickyValue);
      } catch (err) {
        console.error("ERROR: Cannot parse stickyValue", stickyValue);
      }
    }

    return result;
  });

  useEffect(() => {
    isBrowser && window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

// Supports the retrieval and storage of user preferences using
// browser local storage; use a dot-separated path to store/retrieve one
// or more settings for a component or view
export const usePreference = (path, value = null) => {
  const [prefsStr, setPrefsStr] = useStickyState("{}", "dfp:preferences");
  const prefsObj = JSON.parse(prefsStr);
  const myPref = dotProp.get(prefsObj, path, null);
  const setMyPref = (value) => {
    // FIXME: this assumes an object but it's just a stupid fucking single string?
    // WHAT HAPPENED TO THE JSON STRUCTURE???
    const newPrefsObj = dotProp.set(prefsObj, path, value);
    const newPrefs = JSON.stringify(newPrefsObj);

    setPrefsStr(newPrefs);
  };

  // Set initial value if null (FIXME: use sentinel?)
  if (myPref === null) {
    setMyPref(value);
  }

  return [myPref, setMyPref];
};
