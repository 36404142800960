module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#003865","theme_color":"#003865","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-mermaid","options":{"language":"mermaid","theme":"base","viewport":{"width":200,"height":200},"mermaidOptions":{"themeVariables":{"background":"#3f3f3f","textColor":"#ffffff","lineColor":"#ffffff","edgeLabelBackground":"#0d0d0d","mainBkg":"#006298","primaryColor":"#006298","primaryTextColor":"#f7f7f7","primaryBorderColor":"#4cbfff","secondaryColor":"#575757","secondaryTextColor":"#ffffff","secondaryBorderColor":"#ff5757","tertiaryColor":"#007a6d","tertiaryTextColor":"#ffffff","tertiaryBorderColor":"#3dffea","noteBkgColor":"#ffc72c","noteTextColor":"#3f3f3f","noteBorderColor":"#ffc72c","errorBkgColor":"#c61d23","errorTextColor":"#f7f7f7","clusterBkg":"#003865","clusterBorder":"#006298","titleColor":"#ffffff","darkMode":true,"fontSize":13,"fontFamily":"\"Open Sans\",Helvetica,sans-serif"},"flowchart":{"fontFamily":"\"Open Sans\",Helvetica,sans-serif"},"sequence":{"width":120,"height":40,"fontSize":13,"fontFamily":"\"Open Sans\",Helvetica,sans-serif"},"gantt":{"fontFamily":"\"Open Sans\",Helvetica,sans-serif"}}}},"gatsby-remark-prismjs",{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}],"defaultLayouts":{"default":"/projects/app-client/src/components/layout/DocsLayout/DocsLayout.jsx","app":"/projects/app-client/src/components/layout/DocsLayout/DocsLayout.jsx"},"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/projects/app-client"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
