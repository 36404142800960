import React, { forwardRef } from "react";
import classNames from "classnames";

import * as styles from "./styles.module.scss";

export const FeatureMenuNav = forwardRef(({ children, ...other }, ref) => {
  return (
    <div {...other} ref={ref} className={classNames(styles.FeatureMenuNav)}>
      {children}
    </div>
  );
});

FeatureMenuNav.displayName = "FeatureMenuNav";
