// extracted by mini-css-extract-plugin
export var PlatformHeader = "styles-module--PlatformHeader--X2U7L";
export var Container = "styles-module--Container--1h3m5";
export var NavbarCollapse = "styles-module--NavbarCollapse--2Q6Iw";
export var NavbarBrand = "styles-module--NavbarBrand--3irE3";
export var CengageLogo = "styles-module--CengageLogo--2gPro";
export var SiteTitle = "styles-module--SiteTitle--26SDR";
export var brandExpanded = "styles-module--brandExpanded--2yGLk";
export var NavbarNav = "styles-module--NavbarNav--3mvPl";
export var NavbarSeparator = "styles-module--NavbarSeparator--2e-Ug";
export var NavItem = "styles-module--NavItem--2PLTL";
export var NavLink = "styles-module--NavLink--2h_a1";
export var BtnLink = "styles-module--BtnLink--3uPqE";
export var userIcon = "styles-module--user-icon--3rTMP";