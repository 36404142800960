// extracted by mini-css-extract-plugin
export var annotationColor = "var(--annotation-clash-color, #fc4c02)";
export var Figure = "styles-module--Figure--8ZZSF";
export var fullScreen = "styles-module--fullScreen--3rYNf";
export var FigureTitle = "styles-module--FigureTitle--2hb3j";
export var FigureIcon = "styles-module--FigureIcon--1BkFi";
export var FigureStage = "styles-module--FigureStage--Xw7LB";
export var FigureSubject = "styles-module--FigureSubject--3O_33";
export var FigureAnnotations = "styles-module--FigureAnnotations--1LBwn";
export var AnnotationList = "styles-module--AnnotationList--2PYcU";
export var AnnotationListTitle = "styles-module--AnnotationListTitle--CCAm3";
export var AnnotationHelp = "styles-module--AnnotationHelp--nZfXu";
export var AnnotationReset = "styles-module--AnnotationReset--38hVY";
export var AnnotationSubject = "styles-module--AnnotationSubject--3Za1X";
export var AnnotationTargetRect = "styles-module--AnnotationTargetRect--1uNTo";
export var targetRectActive = "styles-module--targetRectActive--28yU9";
export var AnnotationMask = "styles-module--AnnotationMask--Huh0z";
export var FigureAnnotationGroup = "styles-module--FigureAnnotationGroup--2iE0_";
export var active = "styles-module--active--AP0cd";
export var FigureAnnotation = "styles-module--FigureAnnotation--1YDYC";