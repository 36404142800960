import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import Xarrow, { Xwrapper, useXarrow } from "react-xarrows";

import { Icon } from "components/Icon";

import * as styles from "./styles.module.scss";

const getDomElementRect = (elementId, relativeToRef) => {
  const defaultRect = {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  };
  const el = document.getElementById(elementId);
  let resultRect = defaultRect;

  if (el && relativeToRef) {
    const relativeToRect = relativeToRef.current.getBoundingClientRect();
    const elRect = el.getBoundingClientRect();

    resultRect = {
      top: elRect.top - relativeToRect.top,
      left: elRect.left - relativeToRect.left,
      width: elRect.width,
      height: elRect.height,
    };
  }

  return resultRect;
};

const padRectPosition = ({ top, left, width, height }, padding = 8) => {
  return {
    top: top - padding,
    height: height + padding * 2,
    left: left - padding,
    width: width + padding * 2,
  };
};

const FigureTitle = ({
  title,
  figureTypeName = "Figure",
  iconName = "Puzzle",
  showIcon = false,
  fullScreen = false,
  setFullScreen,
  className,
  ...other
}) => {
  return (
    <div
      {...other}
      className={classNames(
        styles.FigureTitle,
        "FigureTitle",
        "d-flex",
        "flex-row",
        "align-items-center"
      )}
    >
      {showIcon && (
        <span className={classNames(styles.FigureIcon)}>
          <Icon name={iconName} title={figureTypeName} />
        </span>
      )}
      <span className={classNames("flex-fill")}>{title}</span>
      {setFullScreen && (
        <Button
          size={"sm"}
          variant={"link"}
          className={classNames(
            "text-light",
            "p-1",
            "my-0",
            "mb-auto",
            "text-small",
            "opacity-75"
          )}
          onClick={() => setFullScreen(!fullScreen)}
        >
          <Icon
            name={fullScreen ? "ArrowCollapse" : "ArrowExpand"}
            title="Toggle Fullscreen"
          />
        </Button>
      )}
    </div>
  );
};

const FigureAnnotation = ({
  text,
  target,
  onClick,
  isActive = false,
  ...other
}) => {
  const updateXarrow = useXarrow();

  return (
    <a
      {...other}
      onClick={onClick}
      // onKeyDown={onClick} // FIXME
      className={classNames(
        styles.FigureAnnotation,
        isActive ? styles.active : null,
        "FigureAnnotation"
      )}
    >
      {text}
    </a>
  );
};

const AnnotationArrowShadows = ({ ...other }) => {
  return (
    <div
      style={{ visibility: "hidden", position: "absolute" }}
      className="AnnotationFilters"
    >
      <svg viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="annotation-line-shadow">
            <feDropShadow
              dx="1"
              dy="1"
              stdDeviation="0.7"
              floodColor="#000"
              floodOpacity="0.75"
            />
            <feDropShadow
              dx="-1"
              dy="-1"
              stdDeviation="0.7"
              floodColor="#000"
              floodOpacity="0.75"
            />
          </filter>
          <filter id="annotation-arrow-shadow">
            <feDropShadow
              dx="0.05"
              dy="0.03"
              stdDeviation="0.05"
              floodColor="black"
              floodOpacity="1"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

const FigureAnnotations = ({
  annotations,
  annotationTitle = "Annotations",
  children,
  fullScreen = false,
  ...other
}) => {
  const relativeToRef = useRef();
  const defaultId = annotations?.length ? annotations[0].id : null;
  const [activeId, setActiveId] = useState(null);
  const [, setRender] = useState({});
  const forceRerender = () => setRender({});
  const arrowConfig = {
    strokeWidth: 3,
    headSize: 5,
    curveness: 0.25,
    arrowBodyProps: {
      style: {
        filter: "url(#annotation-line-shadow)",
      },
    },
    arrowHeadProps: {
      style: {
        filter: "url(#annotation-arrow-shadow)",
      },
    },
  };
  let targetRectStyles = {};
  let targetRectActive = false;
  let annotationClashColor = null;

  // TODO: This will have to watch window resizes, other sources of layout shift?
  // https://github.com/Eliav2/react-xarrows/blob/master/src/Xarrow/Xarrow.tsx#L148
  if (activeId) {
    const activeAnnotation = annotations.find(
      (annotation) => annotation.id === activeId
    );
    const rectPadding = 8;
    const activeTargetRect = padRectPosition(
      getDomElementRect(activeAnnotation.target, relativeToRef),
      rectPadding
    );

    annotationClashColor = activeAnnotation.color || null;

    if (activeAnnotation.mask) {
      targetRectActive = true;
      targetRectStyles = Object.assign(targetRectStyles, {
        top: activeTargetRect.top,
        left: activeTargetRect.left,
        width: activeTargetRect.width,
        height: activeTargetRect.height,
      });
    }
  }

  // Deselect any selections when changing dimensions
  useEffect(() => {
    setActiveId(null);
  }, [fullScreen]);

  useEffect(() => {
    window.addEventListener("resize", forceRerender);

    return () => {
      window.removeEventListener("resize", forceRerender);
    };
  });

  return (
    <Xwrapper>
      <AnnotationArrowShadows />
      <div
        {...other}
        ref={relativeToRef}
        style={{ "--annotation-clash-color": annotationClashColor }}
        className={classNames(styles.FigureAnnotations, "FigureAnnotations")}
      >
        {annotations && (
          <div className={classNames(styles.AnnotationList, "AnnotationList")}>
            <h3
              className={classNames(
                styles.AnnotationListTitle,
                "AnnotationListTitle"
              )}
            >
              {annotationTitle}
            </h3>

            {!activeId ? (
              <p
                className={classNames(styles.AnnotationHelp, "AnnotationHelp")}
              >
                select to activate
              </p>
            ) : (
              <Button
                onClick={() => setActiveId(null)}
                size="sm"
                variant="link"
                className={classNames(styles.AnnotationReset)}
              >
                clear selection
              </Button>
            )}

            {annotations.map((annotation, idx) => {
              const isActive = activeId === annotation.id;

              return (
                <div
                  className={classNames(
                    styles.FigureAnnotationGroup,
                    isActive ? styles.active : null,
                    "FigureAnnotationGroup"
                  )}
                  key={annotation.id}
                >
                  <FigureAnnotation
                    {...annotation}
                    id={annotation.id}
                    tabIndex={0}
                    isActive={isActive}
                    onClick={() => {
                      setActiveId(isActive ? null : annotation.id);
                    }}
                  />
                  <Xarrow
                    {...arrowConfig}
                    divContainerProps={{
                      id: `arrow-${annotation.id}-${annotation.target}`,
                    }}
                    start={annotation.id}
                    startAnchor="right"
                    end={annotation.target}
                    color={styles.annotationColor}
                  />
                </div>
              );
            })}
          </div>
        )}
        <div
          className={classNames(styles.AnnotationSubject, "AnnotationSubject")}
        >
          {children}
        </div>
        <div
          style={targetRectStyles}
          className={classNames(
            styles.AnnotationTargetRect,
            // FIXME: also check if `mask` is true, otherwise don't show it
            targetRectActive ? styles.targetRectActive : null,
            "AnnotationTargetRect"
          )}
        />
      </div>
    </Xwrapper>
  );
};

const FigureStage = ({ children, ...other }) => {
  return (
    <div {...other} className={classNames(styles.FigureStage, "FigureStage")}>
      {children}
    </div>
  );
};

const FigureSubject = ({ children, ...other }) => {
  return (
    <div
      {...other}
      className={classNames(styles.FigureSubject, "FigureSubject")}
    >
      {children}
    </div>
  );
};

const Figure = ({
  subject,
  title,
  figureTypeName = "Figure",
  iconName,
  showIcon = true,
  subjectTag = FigureSubject,
  className,
  annotations,
  annotationTitle,
  // annotationLineColor = "hotpink",
  ...other
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const SubjectTag = subjectTag;

  return (
    <div
      {...other}
      className={classNames(
        styles.Figure,
        fullScreen ? styles.fullScreen : null,
        figureTypeName,
        className
      )}
    >
      <FigureTitle
        title={title}
        figureTypeName={figureTypeName}
        iconName={iconName}
        showIcon={showIcon}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
      />
      <FigureStage>
        <FigureAnnotations
          annotations={annotations}
          annotationTitle={annotationTitle}
          fullScreen={fullScreen}
        >
          <SubjectTag>{subject}</SubjectTag>
        </FigureAnnotations>
      </FigureStage>
    </div>
  );
};

export { FigureTitle, FigureStage, FigureSubject, Figure };
