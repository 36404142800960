import React, { Context, useState, useMemo } from "react";
import classNames from "classnames";
import { useStaticQuery, useScrollRestoration, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { FeatureMenu } from "components/navigation/FeatureMenu";
import { PlatformHeader } from "components/layout/PlatformHeader/PlatformHeader";
import { PlatformFooter } from "components/layout/PlatformFooter/PlatformFooter";
import { usePreference } from "utils/hooks/usePreference";

import { SiteContext } from "./SiteContext";

// Merely importing these includes them as singletons
import magmaTheme from "styles/bootstrap5-magma/bootstrap5-magma.scss";
// import magmaTheme from "styles/bootstrap4-magma.css";
import globalStyles from "styles/styles.scss";
import prismStyles from "styles/prism-dark.scss";

import * as styles from "./styles.module.scss";

const query = graphql`
  query SiteLayoutQuery {
    site {
      metadata: siteMetadata {
        title
      }
    }

    mainMenu: allMainmenuYaml {
      items: edges {
        menuItem: node {
          id
          title
          to
          iconName
          description
        }
      }
    }
  }
`;

// TODO: Extract props/data from rendered template
const SiteLayout = ({
  location,
  navigate,
  pageContext,
  pageResources,
  params,
  path,
  uri,
  children,
  ...other
}) => {
  // Path-related stuff
  // const isRoot = path === "/";
  // const segmentDepth = path?.split("/").filter((pth) => pth !== "").length || 0;

  // Preserve user scroll position by observing/storing this component
  const mainWrapperRestoreScroll = useScrollRestoration(
    "SiteLayout.MainWrapper"
  );

  // Populate header, other metadata from gatsby-config.js
  const data = useStaticQuery(query);
  const mainMenu = useMemo(
    () => data.mainMenu.items.map((item) => item.menuItem),
    [data.mainMenu]
  );

  // Preferences
  const [navOpen, setNavOpen] = usePreference("FeatureMenu.isOpen", true);

  return (
    <div className={classNames("body-container")}>
      <SiteContext.Provider
        value={{ mainMenu: mainMenu, metadata: data.site.metadata }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{data.site.metadata.title}</title>
        </Helmet>
        <a target="#content-start" className="visually-hidden-focusable">
          Skip to content
        </a>
        <PlatformHeader
          brandExpanded={navOpen}
          platformTitle={data.site.metadata.title}
        />
        {/* <div class="announcements" id="dfp-announce">TODO</div> */}
        <div
          className={classNames(
            styles.BodyWrapper,
            "d-flex",
            "flex-row",
            "flex-grow-1",
            "align-items-stretch",
            "overflow-auto"
          )}
        >
          <FeatureMenu
            isOpen={navOpen}
            setIsOpen={() => setNavOpen(!navOpen)}
            menuItems={mainMenu}
            menuTitle="Generic Views"
          />
          <div
            className={classNames(styles.MainWrapper)}
            {...mainWrapperRestoreScroll}
          >
            <div
              className={classNames(
                "bg-light",
                "d-flex",
                "flex-column",
                "flex-expand-1",
                "flex-fill"
              )}
            >
              <a id="content-start" className="visually-hidden"></a>
              {children}
            </div>
          </div>
        </div>
        <PlatformFooter />
      </SiteContext.Provider>
    </div>
  );
};

export { query, SiteLayout };
export default SiteLayout;
