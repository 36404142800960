import React from "react";
import classNames from "classnames";

import * as styles from "./styles.module.scss";

export const FeatureMenuWrapper = ({ isOpen = false, children, ...other }) => {
  return (
    <div
      {...other}
      className={classNames(
        styles.FeatureMenuWrapper,
        isOpen ? styles.isOpen : null
      )}
    >
      {children}
    </div>
  );
};
