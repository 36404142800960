import React from "react";
import classNames from "classnames";

const PlatformFooter = ({ versionNumber = "X.Y.Z" }) => {
  return (
    <div
      className={classNames(
        "footer",
        "mt-auto",
        "bg-light",
        "border-light",
        "border-top"
      )}
    >
      <div className={classNames("container-fluid")}>
        <div className={classNames("row", "mt-2", "mb-2")}>
          <small className={classNames("col-md-6")}>
            version {versionNumber}
          </small>
          <small className={classNames("col-md-6", "text-md-end")}>
            copyright etc.
          </small>
        </div>
      </div>
    </div>
  );
};

export { PlatformFooter };
