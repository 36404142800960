// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-docs-developer-index-mdx": () => import("./../../../src/pages/docs/developer/index.mdx" /* webpackChunkName: "component---src-pages-docs-developer-index-mdx" */),
  "component---src-pages-docs-index-mdx": () => import("./../../../src/pages/docs/index.mdx" /* webpackChunkName: "component---src-pages-docs-index-mdx" */),
  "component---src-pages-docs-user-index-mdx": () => import("./../../../src/pages/docs/user/index.mdx" /* webpackChunkName: "component---src-pages-docs-user-index-mdx" */),
  "component---src-pages-docs-view-mdx": () => import("./../../../src/pages/docs-view.mdx" /* webpackChunkName: "component---src-pages-docs-view-mdx" */),
  "component---src-pages-edit-view-index-mdx": () => import("./../../../src/pages/edit-view/index.mdx" /* webpackChunkName: "component---src-pages-edit-view-index-mdx" */),
  "component---src-pages-edit-view-introduction-jsx": () => import("./../../../src/pages/edit-view/introduction.jsx" /* webpackChunkName: "component---src-pages-edit-view-introduction-jsx" */),
  "component---src-pages-edit-view-kitchen-sink-jsx": () => import("./../../../src/pages/edit-view/kitchen-sink.jsx" /* webpackChunkName: "component---src-pages-edit-view-kitchen-sink-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-list-view-index-jsx": () => import("./../../../src/pages/list-view/index.jsx" /* webpackChunkName: "component---src-pages-list-view-index-jsx" */),
  "component---src-pages-mock-stage-index-jsx": () => import("./../../../src/pages/mock-stage/index.jsx" /* webpackChunkName: "component---src-pages-mock-stage-index-jsx" */),
  "component---src-pages-mock-stage-page-2-mdx": () => import("./../../../src/pages/mock-stage/page-2.mdx" /* webpackChunkName: "component---src-pages-mock-stage-page-2-mdx" */),
  "component---src-pages-node-editor-jsx": () => import("./../../../src/pages/node-editor/[...].jsx" /* webpackChunkName: "component---src-pages-node-editor-jsx" */),
  "component---src-pages-svg-loading-mdx": () => import("./../../../src/pages/svg-loading.mdx" /* webpackChunkName: "component---src-pages-svg-loading-mdx" */),
  "component---src-pages-table-view-index-jsx": () => import("./../../../src/pages/table-view/index.jsx" /* webpackChunkName: "component---src-pages-table-view-index-jsx" */),
  "component---src-pages-tree-view-index-jsx": () => import("./../../../src/pages/tree-view/index.jsx" /* webpackChunkName: "component---src-pages-tree-view-index-jsx" */)
}

