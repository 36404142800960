import React from "react";
import classNames from "classnames";

import { Button, Breadcrumb, Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "gatsby";
import { Icon } from "components/Icon";

import CengageLogoLight from "images/svgr/cengage_logo_light_2.svg";

import * as styles from "./styles.module.scss";

export function PlatformHeader({
  platformTitle = "Cengage",
  brandExpanded = true,
  openWidth = "260px",
  closedWidth = "64px",
}) {
  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="md"
      className={classNames(styles.PlatformHeader, "xbg-gradient")}
    >
      <Container fluid className={classNames(styles.Container)}>
        <Navbar.Brand
          as={Link}
          to="/"
          style={{
            "--closed-width": closedWidth,
            "--open-width": openWidth,
          }}
          className={classNames(
            styles.NavbarBrand,
            brandExpanded ? styles.brandExpanded : null
          )}
        >
          <CengageLogoLight
            alt="Cengage"
            className={classNames(
              styles.CengageLogo,
              "d-inline-block",
              "align-middle"
            )}
          />
          <span className={classNames(styles.SiteTitle)}>{platformTitle}</span>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className={classNames(styles.NavbarCollapse)}>
          <Nav
            className={classNames(
              styles.NavbarNav,
              "navbar-nav",
              "me-auto",
              "mb-lg-0",
              "flex-fill",
              "justify-content-end"
            )}
          >
            {/* <NavbarDivider /> */}

            {/* <Breadcrumb className={"nav-breadcrumb align-self-start"}>
              <Breadcrumb.Item href="#/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="#/todo">TODO</Breadcrumb.Item>
              <Breadcrumb.Item href="#/todo/fixme" active>
                FIXME
              </Breadcrumb.Item>
            </Breadcrumb> */}
            <Nav.Item className={classNames("nav-search", "py-1", "px-2")}>
              <form>
                <div className="input-group me-3">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search ${context}"
                    aria-label="Search ${context}"
                    aria-describedby="submit-global-search"
                  />
                  <button
                    className={classNames("btn", "btn-outline-light")}
                    style={{ padding: "0 0.65rem" }}
                    type="submit"
                    id="submit-global-search"
                    aria-label="Search"
                    title="Search"
                  >
                    <Icon
                      name="Magnify"
                      size={0.85}
                      title="Search"
                      alignText={true}
                    />
                  </button>
                </div>
              </form>
            </Nav.Item>

            <Nav.Item className={classNames(styles.NavItem)}>
              <Nav.Link
                as={Button}
                variant="link"
                className={classNames(
                  styles.NavLink,
                  styles.BtnLink,
                  "btn-circle"
                )}
              >
                <Icon name="Bell" size={0.9} alignText={true} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Button}
                variant="link"
                className={classNames(
                  styles.NavLink,
                  styles.BtnLink,
                  "btn-circle"
                )}
              >
                <Icon name="Account" size={0.9} alignText={true} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
