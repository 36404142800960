import React, { forwardRef } from "react";
import MaterialDesignIcon from "@mdi/react";
import classNames from "classnames";
import { getMaterialIconPath } from "../../utils/getMaterialIconPath";

import * as styles from "./styles.module.css";

// https://github.com/Templarian/MaterialDesign-React/blob/master/README.md
export const Icon = forwardRef(
  (
    {
      name,
      title = null,
      description = null,
      size = null,
      horizontal = false,
      vertical = false,
      rotate = 0,
      color = null,
      spin = false,
      menu = false,
      alignText = false,
      className,
      ...other
    },
    ref
  ) => {
    const iconProps = {
      ...other,
      title,
      description,
      size,
      horizontal,
      vertical,
      rotate,
      color,
      spin
    };

    if (!iconProps.title) {
      iconProps.title = `${name} icon`;
    }

    if (!iconProps.size) {
      iconProps.size = 1;
    }

    return (
      <MaterialDesignIcon
        {...iconProps}
        ref={ref}
        className={classNames(
          styles.Icon,
          menu ? styles.menu : null,
          alignText ? styles.alignText : null,
          className,
          "icon"
        )}
        path={getMaterialIconPath(name)}
      />
    );
  }
);

Icon.displayName = "Icon";

// TODO: IconStack
