
import * as MaterialDesignIconPaths from "@mdi/js";

export const getMaterialIconPath = (iconName, defaultIconName = "Puzzle") => {
  // Help developers out if they don't capitalize the first letter
  const useIconName = iconName.charAt(0).toUpperCase() + iconName.substr(1);

  const defaultIcon = defaultIconName
    ? MaterialDesignIconPaths[`mdi${defaultIconName}`] || null
    : null;
  return MaterialDesignIconPaths[`mdi${useIconName}`] || defaultIcon || null;
};
