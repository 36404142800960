import { Figure1, Figure2, Figure3, Figure4, Figure5, Figure6 } from "mocks/edit-view/Figures";
import * as React from 'react';
export default {
  Figure1,
  Figure2,
  Figure3,
  Figure4,
  Figure5,
  Figure6,
  React
};