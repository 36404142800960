import React from "react";
import Nav from "react-bootstrap/Nav";

import {
  FeatureMenuNav,
  FeatureMenuLink,
  FeatureMenuWrapper,
  FeatureMenuToggle,
} from ".";

export const FeatureMenu = ({
  menuItems = [],
  menuTitle,
  isOpen = true,
  setIsOpen = () => {},
  openWidth = "260px",
  closedWidth = "64px",
}) => {
  return (
    <FeatureMenuWrapper
      isOpen={isOpen}
      style={{ "--open-width": openWidth, "--closed-width": closedWidth }}
    >
      <FeatureMenuToggle
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={menuTitle}
      />
      <Nav as={FeatureMenuNav}>
        {menuItems.map((menuProps) => {
          const { id, ...linkProps } = menuProps;
          return <FeatureMenuLink key={id} {...linkProps} />;
        })}
      </Nav>
    </FeatureMenuWrapper>
  );
};
