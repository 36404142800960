import React, { useState, useLayoutEffect } from "react";
import classNames from "classnames";

import * as styles from "./styles.module.scss";

export const FeatureMenuToggle = ({
  isOpen = false,
  // setIsOpen = () => {},
  setIsOpen,
  isActive = false,
  title,
  expandLabel = "Expand",
  collapseLabel = "Collapse",
}) => {
  const [showActive, setShowActive] = useState(false);

  useLayoutEffect(() => {
    setShowActive(isActive);
  }, [isActive]);

  return (
    <div className={classNames(styles.FeatureMenuToggle)}>
      <button
        onMouseEnter={() => setShowActive(true)}
        onMouseLeave={() => setShowActive(false)}
        onFocus={() => setShowActive(true)}
        onBlur={() => setShowActive(false)}
        onClick={() => setIsOpen(!isOpen)}
        className={classNames(
          "btn",
          "btn-circle",
          styles.ToggleButton,
          styles.Hamburger,
          isOpen ? styles.HamburgerLeftArrow : styles.HamburgerRightArrow,
          showActive ? styles.isActive : null
        )}
        type="button"
        role="switch"
        aria-checked={isOpen}
        title={isOpen ? collapseLabel : expandLabel}
      >
        <span className={classNames(styles.HamburgerBox)}>
          <span className={classNames(styles.HamburgerInner)} />
        </span>
      </button>
      <span className={classNames(styles.MenuTitle)}>{title}</span>
    </div>
  );
};
