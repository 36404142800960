import React from "react";
import classNames from "classnames";
// import { LinkContainer } from "react-router-bootstrap";
import { Link } from "gatsby"; // Do NOT use @reach/router Link, it WON'T WORK!

import { Icon } from "components/Icon";

import * as styles from "./styles.module.scss";

export const FeatureMenuLink = ({
  title,
  iconName = "Puzzle",
  to = null,
  active = false,
  disabled = false,
  ...other
}) => {
  return (
    <Link
      to={to}
      className={classNames(
        styles.FeatureMenuLink,
        disabled ? styles.disabled : null
        // active ? styles.active : null
      )}
      getProps={({ isCurrent, isPartiallyCurrent, href }) => {
        return isCurrent || (isPartiallyCurrent && href !== "/")
          ? {
              className: classNames(
                styles.FeatureMenuLink,
                disabled ? styles.disabled : null,
                styles.active
              ),
            }
          : {};
      }}
      activeClassName={styles.active}
      disabled={disabled}
      tabIndex={disabled ? "-1" : "0"}
      {...other}
    >
      <span className={classNames(styles.FeatureIcon)}>
        <Icon name={iconName} size={1} alignText title={title} />
      </span>
      <span className={classNames(styles.FeatureTitle)}>{title}</span>
    </Link>
  );
};
